import Thumbnail from '@elements/Thumbnail';
import { useEnvContext } from '@helpers/envProvider';
import { getThumbnailUrl } from '@helpers/getThumbnailUrl';

export default function Carousel(props) {
  const { documents, documentFavorites } = props;
  const { environment } = useEnvContext();
  const NEXT_PUBLIC_PROXY = environment['NEXT_PUBLIC_PROXY'];

  return (
    <div className="flex snap-x snap-mandatory gap-2 overflow-y-auto">
      {documents.map((document, index) => {
        const documentId = document.documentId ? document.documentId : document.document_id;
        const documentType = document.documentType ? document.documentType : document.document_type;
        const publicationDate = document.publicationDate
          ? document.publicationDate
          : document.publication_date;

        return (
          <div className="flex shrink-0 snap-start items-center justify-center" key={index}>
            <Thumbnail
              documentId={documentId}
              title={document.title}
              documentType={documentType}
              publicationDate={publicationDate}
              isFavorite={
                documentFavorites?.some((fav) => fav.documentId == document.documentId)
                  ? true
                  : false
              }
              thumbnailUrl={getThumbnailUrl(NEXT_PUBLIC_PROXY, document.document_id)}
              thumbnailAlt={document.thumbnailAlt}
            />
          </div>
        );
      })}
    </div>
  );
}
